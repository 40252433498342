.App {
  width: 100%;
  /* text-align: center; */
  /* background-color: #191919; */
  /* background: url("../public/imgs/bg-main.jpg"); */
  background: transparent;
}

body {
  background: transparent url("../public/imgs/bg-main.jpg");
  background-size: 100%;
  padding: 0;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.multiSelectContainer li {
  background-color: #000;
}
.multiSelectContainer {
  width: 95%;
  font-size: 12px;
}

.headerSection {
  height: 65px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* padding-left: 1%; */
  padding-right: 1%;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #b090f0;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
.layoutOption {
  /* background: transparent url("../public/imgs/bg-main.jpg"); */
  background: transparent url("../public/imgs/bg-main.jpg") 0% 0% no-repeat
    padding-box;
  border: 0.5px solid #8d8d8d;
  border-radius: 5px;
  opacity: 1;
  color: white;
  margin-top: 2.5%;
  justify-items: center;
}
.layoutOption:hover {
  /* background-color: #7701d8; */
  color: white;
}
.scene-tooltip {
  color: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.65);
  padding: 5px;
}
.graph-tooltip {
  position: absolute;
  transform: translate(-50%, 25px);
  font-family: sans-serif;
  font-size: 16px;
  padding: 4px;
  border-radius: 3px;
  color: rgb(212, 38, 38);
  background: rgba(0, 0, 0, 0.65);
  /* visibility: hidden; by default */
}
.select-search__select {
  background-color: None;
}
.infoCard {
  width: 40%;
  background-color: #171717;
  color: white;
  border: 0.5px solid #b9b9b9;
  border-radius: 5px;
  opacity: 0.75;
}
.infoCardFilter {
  width: 300px;
  background-color: rgba(255, 255, 255, 0.25);
  color: white;
  position: absolute;
  z-index: 9;
  border: 2px solid #fff;
  border-radius: 5px;
  margin: 0.8rem;
  opacity: 1;
  font-family: "Nunito Sans", sans-serif;
  /* background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center; */
}
.infoCardFilterHeader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  color: #fff;
  /* font-size: 22px; */
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
}
.infoCardFilterCardBody {
  /* height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin; */
  padding-bottom: 30px;
  /* scrollbar-color: rgba(229, 229, 229, 0.6) rgba(229, 229, 229, 0.4); */
}
.multiselect-form-label {
  margin-top: 10px;

  text-align: left;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}
.filter-button {
  background: #47127d;
  border-radius: 4px;
  width: 100px;
  border: 1px solid #47127d;
  outline: none;
}
.reset-button {
  background: #f72a51;
  border-radius: 4px;
  border-collapse: none;
  width: 100px;
  margin-left: 10px;
  border: 1px solid #f72a51;
  outline: none;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-text {
  color: #b090f0;
  margin-top: 0.6rem;
}
.header-tab-pill-container {
  background: rgba(229, 229, 229, 0.4);
}
.header-button-active {
  background: #6605f7 !important;
  color: white !important;
  outline: none;
  border: none;
  border-radius: 3px;
  color: black;
  background-color: white;
  width: 130px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 3px;
}
.special-text {
  text-decoration: underline;
}
.company-name {
  color: #000;
  margin-left: 10px;
  margin-top: 2px;
}
.labelCard {
  width: 400px;
  color: #fff;
  border: 1.5px solid white;
  border-radius: 5px;
  align-content: center;
  cursor: "move";
}
.label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px;
}
.label-text {
  margin-left: 4px;
}
.label-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.node-details-card {
  width: 300px;
  margin: 0.8rem;
}
.node-details-card-body-hei {
  /* height: 80vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(229, 229, 229, 0.6) rgba(229, 229, 229, 0.4); */
}
.node-details-card-body {
  color: #fff;
  text-align: center;
  padding: 3px;
  border: 1px dashed white;
  border-radius: 5px;
}
.node-details-item-name {
  color: #fff;
  margin-top: 1px;
}
.text-white {
  color: #fff;
}
.node-details-borders {
  padding: 3px;
  padding-left: 15px;
  border-radius: 5px;
}
.zoom-container {
  margin: 0.8rem;
  color: #fff;
  padding: 15px;
  align-content: center;
  cursor: move;
}
.child-nodes-div {
  display: flex;
  flex-direction: row;
  padding: 6px;
  color: white;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  align-items: center;
  height: 32px;
  background-color: rgba(0,0,0,0.3);
}
.zoom-arrow-clock {
  color: #cacaca 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.zoom-zoomIn {
  margin-top: 15px;
  color: #cacaca 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.dashedBorder {
  border: 1px dashed white;
  padding-left: "15px" !important;
  margin-top: 1px;
}
.infoCardDetails {
  background-color: rgba(255, 255, 255, 0.25);
  color: white;
  position: absolute;
  z-index: 8;
  border: 2px solid #fff;
  border-radius: 5px;
  opacity: 1;
  /* background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center; */
}
.infoCardDetailsHeader {
  padding: 10px;
  cursor: move;
  z-index: 11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  /* background-color: #2196F3;
    color: #fff; */
}
.labelCardDetails {
  background-color: #171717;
  color: white;
  position: absolute;
  z-index: 8;
  opacity: 0.75;
}
.labelCardDetailsHeader {
  background-color: #171717;
  color: white;
  position: absolute;
  z-index: 8;
}
.zoom-symbol {
  border: 1px solid white;
  padding: 0px 14px 10px 14px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: "6px";
}
.zoomCardDetails {
  background-color: #17171700;
  color: white;
  position: absolute;
  z-index: 8;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.loader-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zoomCardDetailsHeader {
  background-color: #171717;
  color: white;
  position: absolute;
  z-index: 8;
}
.row > * {
  padding: 0px;
}
.kg {
  background: transparent;
  width: 100%;
  /*height: 100vh;*/
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-search {
  width: 200px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
  font-size: 8px;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
  font-size: 8px;
}

/**
  * Value wrapper
  */
.select-search__value {
  position: relative;
  z-index: 1;
  font-size: 8px;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
  font-size: 8px;
}

/**
  * Input
  */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;

  background: #101010 0% 0% no-repeat padding-box;
  border: 0.5px solid #8d8d8d;
  border-radius: 5px;
  opacity: 1;
  color: white;

  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}
.header-button {
  outline: none;
  border: none;
  border-radius: 3px;
  color: black;
  background-color: white;
  width: 130px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 3px;
}
.header-button:focus {
  outline: none;
  border: none;
}
.nav-text {
  color: black;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link 
    /* background-color: #0d6efd; */ {
  background: none;
  border-radius: 0;
  color: black;
  /* border-bottom: #6F2D80 5px solid; */
}
.border-nav {
  width: 10px;
  height: 5px;
  /* background: #6f2d80; */
}
/**
  * Options wrapper
  */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
  * Options
  */
.select-search__options {
  list-style: none;
}

/**
  * Option row
  */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
  font-size: 8px;
}

/**
  * Option
  */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
  * Group
  */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
  * States
  */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
  * Modifiers
  */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}
.position-absolute {
  position: absolute;
}
.curser-move {
  cursor: move;
}

.node-label {
  font-size: 12px;
  padding: 1px 4px;
  border-radius: 4px;
  background-color: rgba(0,0,0,0.5);
  user-select: none;
  color: rgba(255,255,255,0.8) !important;
}

.chip {
  white-space: normal;
}